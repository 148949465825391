<template>
    <div class="footer-panel" :class="{'no-lang': isMultilangOff}">
        <div class="footer-contact-us">
            <div class="pannel-controls">
                <router-link class="a-common-hover" to="/privacy-policy">{{ 'プライバシーポリシー' }}</router-link>
            </div>
            <div class="social-networks">
                <div class="social-networks-wrapper">
                     <template v-if="'ja' == 'en'">
                        <a href="//www.facebook.com/dnd.promotion/" target="_blank" rel="noopener noreferrer">
                            <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                                <path d="M32 0C14.3269 0 0 14.3269 0 32c0 15.9721 11.702 29.2106 27 31.6113V41.25h-8.125V32H27v-7.05c0-8.02 4.7774-12.45 12.0869-12.45 3.501 0 7.1631.625 7.1631.625V21h-4.0351C38.2397 21 37 23.4667 37 25.9973V32h8.875l-1.4188 9.25H37v22.3613C52.298 61.2106 64 47.972 64 32 64 14.3269 49.6731 0 32 0z" fill-rule="evenodd"></path>
                            </svg>
                        </a>
                        <a href="//www.instagram.com/dnd.promotion/" target="_blank" rel="noopener noreferrer">
                            <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="1.414">
                                <path d="M8 0C5.827 0 5.555.01 4.702.048 3.85.088 3.27.222 2.76.42c-.526.204-.973.478-1.417.923-.445.444-.72.89-.923 1.417-.198.51-.333 1.09-.372 1.942C.008 5.555 0 5.827 0 8s.01 2.445.048 3.298c.04.852.174 1.433.372 1.942.204.526.478.973.923 1.417.444.445.89.72 1.417.923.51.198 1.09.333 1.942.372.853.04 1.125.048 3.298.048s2.445-.01 3.298-.048c.852-.04 1.433-.174 1.942-.372.526-.204.973-.478 1.417-.923.445-.444.72-.89.923-1.417.198-.51.333-1.09.372-1.942.04-.853.048-1.125.048-3.298s-.01-2.445-.048-3.298c-.04-.852-.174-1.433-.372-1.942-.204-.526-.478-.973-.923-1.417-.444-.445-.89-.72-1.417-.923-.51-.198-1.09-.333-1.942-.372C10.445.008 10.173 0 8 0zm0 1.44c2.136 0 2.39.01 3.233.048.78.036 1.203.166 1.485.276.374.145.64.318.92.598.28.28.453.546.598.92.11.282.24.705.276 1.485.038.844.047 1.097.047 3.233s-.01 2.39-.05 3.233c-.04.78-.17 1.203-.28 1.485-.15.374-.32.64-.6.92-.28.28-.55.453-.92.598-.28.11-.71.24-1.49.276-.85.038-1.1.047-3.24.047s-2.39-.01-3.24-.05c-.78-.04-1.21-.17-1.49-.28-.38-.15-.64-.32-.92-.6-.28-.28-.46-.55-.6-.92-.11-.28-.24-.71-.28-1.49-.03-.84-.04-1.1-.04-3.23s.01-2.39.04-3.24c.04-.78.17-1.21.28-1.49.14-.38.32-.64.6-.92.28-.28.54-.46.92-.6.28-.11.7-.24 1.48-.28.85-.03 1.1-.04 3.24-.04zm0 2.452c-2.27 0-4.108 1.84-4.108 4.108 0 2.27 1.84 4.108 4.108 4.108 2.27 0 4.108-1.84 4.108-4.108 0-2.27-1.84-4.108-4.108-4.108zm0 6.775c-1.473 0-2.667-1.194-2.667-2.667 0-1.473 1.194-2.667 2.667-2.667 1.473 0 2.667 1.194 2.667 2.667 0 1.473-1.194 2.667-2.667 2.667zm5.23-6.937c0 .53-.43.96-.96.96s-.96-.43-.96-.96.43-.96.96-.96.96.43.96.96z"></path>
                            </svg>
                        </a>
                    </template>
                     <template v-if="'ja' == 'ja'">
                        <a href="//www.facebook.com/dnd.promotion.jp/" target="_blank" rel="noopener noreferrer">
                            <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                                <path d="M32 0C14.3269 0 0 14.3269 0 32c0 15.9721 11.702 29.2106 27 31.6113V41.25h-8.125V32H27v-7.05c0-8.02 4.7774-12.45 12.0869-12.45 3.501 0 7.1631.625 7.1631.625V21h-4.0351C38.2397 21 37 23.4667 37 25.9973V32h8.875l-1.4188 9.25H37v22.3613C52.298 61.2106 64 47.972 64 32 64 14.3269 49.6731 0 32 0z" fill-rule="evenodd"></path>
                            </svg>
                        </a>
                        <a href="//www.instagram.com/dndpromo/" target="_blank" rel="noopener noreferrer">
                            <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="1.414">
                                <path d="M8 0C5.827 0 5.555.01 4.702.048 3.85.088 3.27.222 2.76.42c-.526.204-.973.478-1.417.923-.445.444-.72.89-.923 1.417-.198.51-.333 1.09-.372 1.942C.008 5.555 0 5.827 0 8s.01 2.445.048 3.298c.04.852.174 1.433.372 1.942.204.526.478.973.923 1.417.444.445.89.72 1.417.923.51.198 1.09.333 1.942.372.853.04 1.125.048 3.298.048s2.445-.01 3.298-.048c.852-.04 1.433-.174 1.942-.372.526-.204.973-.478 1.417-.923.445-.444.72-.89.923-1.417.198-.51.333-1.09.372-1.942.04-.853.048-1.125.048-3.298s-.01-2.445-.048-3.298c-.04-.852-.174-1.433-.372-1.942-.204-.526-.478-.973-.923-1.417-.444-.445-.89-.72-1.417-.923-.51-.198-1.09-.333-1.942-.372C10.445.008 10.173 0 8 0zm0 1.44c2.136 0 2.39.01 3.233.048.78.036 1.203.166 1.485.276.374.145.64.318.92.598.28.28.453.546.598.92.11.282.24.705.276 1.485.038.844.047 1.097.047 3.233s-.01 2.39-.05 3.233c-.04.78-.17 1.203-.28 1.485-.15.374-.32.64-.6.92-.28.28-.55.453-.92.598-.28.11-.71.24-1.49.276-.85.038-1.1.047-3.24.047s-2.39-.01-3.24-.05c-.78-.04-1.21-.17-1.49-.28-.38-.15-.64-.32-.92-.6-.28-.28-.46-.55-.6-.92-.11-.28-.24-.71-.28-1.49-.03-.84-.04-1.1-.04-3.23s.01-2.39.04-3.24c.04-.78.17-1.21.28-1.49.14-.38.32-.64.6-.92.28-.28.54-.46.92-.6.28-.11.7-.24 1.48-.28.85-.03 1.1-.04 3.24-.04zm0 2.452c-2.27 0-4.108 1.84-4.108 4.108 0 2.27 1.84 4.108 4.108 4.108 2.27 0 4.108-1.84 4.108-4.108 0-2.27-1.84-4.108-4.108-4.108zm0 6.775c-1.473 0-2.667-1.194-2.667-2.667 0-1.473 1.194-2.667 2.667-2.667 1.473 0 2.667 1.194 2.667 2.667 0 1.473-1.194 2.667-2.667 2.667zm5.23-6.937c0 .53-.43.96-.96.96s-.96-.43-.96-.96.43-.96.96-.96.96.43.96.96z"></path>
                            </svg>
                        </a>
                    </template>
                </div>
            </div>
           <div v-if="!isMultilangOff" class="lang-select-wrapper">
                <langSelect/>
            </div>
            <div v-if="!isMultilangOff" class="lang-select-wrapper-mobile">
                <langSelectMobile/>
            </div>
            <div class="copyright">
                <p>{{ '© D&D Promotion, LLC.' }}</p>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    data() {
        return {
            isMultilangOff: true
        }
    },
    components: {
        langSelect: () => import('./../components/lang-select'), 
        langSelectMobile: () => import('./../components/lang-select-mobile')
    }
}
</script>
<style lang="scss" scoped>
@import '../scss/app';

.footer-panel {
     position: relative;
     background-color: #191a1a;
     z-index: 10;
     height: 150px;
}

.footer-contact-us {
    bottom: 52px;
    left: 52px;
    right: 52px;
    position: absolute;
}

.pannel-controls {
    margin-bottom: 12px;
    a {
        color: #DCE6E6;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        margin-right: 24px;
        font-size: 14px;
        display: inline-block;
        transition: 300ms cubic-bezier(0.86, 0, 0.07, 1);
        font-family: $family-medium;
        text-decoration: none;
    }
}    

.copyright {
    p {
        color: #626666;
        font-size: 14px;
        font-family: $family-light;
    }
}

.social-networks {
    position: absolute;
    bottom: -10px;
    right: 0;

    .social-networks-wrapper {
        display: flex;
    }
    

    a {
        display: block;
        margin-bottom: 16px;
        width: 0.8vw;
        min-width: 24px;
        opacity: 0.7;
        margin-left: 16px;
    }

    svg {
        fill: #DCE6E6;
        transition: 300ms ease;
    }

    svg:hover {
        fill: rgb(51, 50, 50);
        transition: 300ms ease;
    }
}

.lang-select-wrapper-mobile {
    display: none;
}

.lang-select-wrapper {
    right: 40px;
    position: absolute;
    bottom: 0;
}

@media (max-width: 992px) {

        .lang-select-wrapper {
            display: none;
        }

        .lang-select-wrapper-mobile {
            display: block;
            padding: 10px 0 15px;
        }
      .footer-contact-us {
        bottom: 40px;
        left: 40px;
        right: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .pannel-controls {
        margin-bottom: 0;
        a {
            margin: 0 5px;
        }
    }

    .social-networks {
        position: static;
    }

    .social-networks-wrapper {
        display: flex;
        justify-content: space-between;

        a {
            margin: 10px;
        }
    }

    .desctop-select {
        display: none;
    }

    .footer-panel {
        height: 240px;

        &.no-lang {
            height: 170px;
        }
    }
}

@media (max-width: 576px) {

}

</style>


