<template>
  <div class="lp-youtube-promotion">
    <cookies-popup />
    <iframe
      id="bg-frame"
      class="frame-bg frame-bg-visible"
      scrolling="no"
      src="./backgrounds/city-lp.html"
    ></iframe>
    <div class="lp-youtube-promotion--header" :class="{ hidden: isHeroHidden }">
      <div class="lp-youtube-promotion--nav">
        <div class="lp-youtube-promotion--nav-inner">
          <router-link to="/" class="lp-youtube-promotion--logo-container">
            <img
              class="lp-youtube-promotion--logo"
              src="/images/logo/D&D_h_logo_full_white.png"
              alt="D&amp;D logo"
            />
          </router-link>
          <div class="lp-youtube-promotion--nav-title">
            <h3>法人向け YouTube広告の最適化</h3>
          </div>
        </div>
      </div>
      <div class="lp-youtube-promotion--header-body">
        <div class="lp-youtube-promotion--header-body-inner">
          <h1 :class="{ 'ja': 'ja' == 'ja' }" v-html="'YouTubeの再生を最安値で増やす<br>本場アメリカの「BOOST」戦略'"></h1>
          <div class="form-wrapper">
            <div class="form-inner">
              <lpForm />
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="lp-youtube-promotion--content">
      <div class="lp-youtube-promotion--content-inner">
        <div class="lp-youtube-promotion--side-image-conainer">
          <img
            src="/images/youtube-promo-lp/yotube-button.jpg"
            alt="yotube icon in iphone"
          />
        </div>
        <p>
          YouTubeとはGoogleが運営する世界最大規模の動画配信サイトです。日本だけでも、月間6,200万以上のユーザーがYouTubeを利用しています。D&D promotionのデジタルプロモーションサービスは、動画を使って自社のアピールをしたいという企業やブランド、新曲や番組のプロモーションを効率化したいメディア関係者に特化しています。「YouTubeの視聴回数を増やしたい」や「チャンネル登録者数を増やしたい」というどちらのご要望にも対応できます。
        </p>
        <p class="padding-bottom">
          当社のYouTubeマーケティングのスペシャリストは、効果的かつ実績のある戦略を通じて、お客様のオーディエンスに効率的にアプローチすることで、より多くの購入者とファンの獲得に貢献致します。D&D promotionは、スピーディーで効率的なYouTubeプロモーションを実現するだけではなく、当社ならではのノウハウと幅広い業界ネットワークを活用したインフルエンサーマーケティングや完全にオーダーメイドのデジタルプロモーションも実施可能です。お客様の身近な相談役として、運用担当者が直接ご相談を承ります。まずはお気軽にお問い合わせください。
        </p>
        <h2 :class="{ 'ja': 'ja' == 'ja' }" v-html="'D&D promotionのデジタルプロモーションが<br/>お客様に選ばれるのには、理由があります。'"></h2>
        <div>
          <div class="addition-row">
            <strong>抜群の費用対効果</strong>
            他のマーケティング代理店よりも競争力のある魅力的な価格をご提案させていただきます。
          </div>
          <div class="addition-row">
            <strong>業界最速のデリバリースピード</strong>
            スピーディーなプロモーションの実行により、即座に再生回数の増加とその広告効果を実感できます。
          </div>
          <div class="addition-row">
            <strong>認知向上のさらに次へ</strong>
            「購入」と「コンバージョン」につながる広告戦略を実施致します。
          </div>
          <div class="addition-row">
            <strong>お客様の安心と信頼を第一に</strong>
            選任の「運用アドバイザー」がお客様のYouTubeプロモーションキャンペーンを徹底的にサポート致します。
          </div>
        </div>
      </div>
    </section>
    <div class="clients">
      <div class="clients-inner">
        <h4 class="clients-title" :class="{ 'ja': 'ja' == 'ja' }" v-html="'あらゆる業種、規模の<br>お客様に選ばれています'"></h4>
        <div class="clients-list">
          <div class="clients-list-item music-group-logo">
              <img src="images/youtube-promo-lp/partners/Universal_Logo.png" alt="Universal Music Group">
          </div>
          <div class="clients-list-item shiseido-logo">
              <img src="images/youtube-promo-lp/partners/Shiseido_Logo.png" alt="SHISEIDO">
          </div>
          <div class="clients-list-item kao-logo">
              <img src="images/youtube-promo-lp/partners/Kao_Logo.png" alt="KAO">
          </div>
          <div class="clients-list-item mitsubishi-logo">
              <img src="images/youtube-promo-lp/partners/Mitsubushi_Logo.png" alt="MITSUBISHI">
          </div>
          <div class="clients-list-item poobear-logo">
              <img src="images/youtube-promo-lp/partners/Pepsi_Logo.png" alt="Pepsi Logo">
          </div>
          <div class="clients-list-item sena-kana-logo">
              <img src="images/youtube-promo-lp/partners/Warner_Logo.png" alt="Warner Logo">
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-form-section">
      <lpForm />
    </div>
    <stub />
    <footerSection />
  </div>
</template>
<script>
import stub from "./../sections/stub";
import footerSection from "./../sections/footer-small-jp";
import lpForm from "./../components/forms/youtube-lp-form-ja";
import cookiesPopup from './../components/cookies-popup'

export default {
  data() {
    return {
      mobileWidth: 793,
      tabletWidth: 992,
      isHeroHidden: true
    };
  },
  metaInfo: {
    title: "D&D Promotion - YouTube Promotion"
  },
  components: {
    stub,
    footerSection,
    lpForm,
    cookiesPopup
  },
  methods: {
    getWindowWidth() {
      return (
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
      );
    }
  },
  computed: {
    isMobile() {
      return this.getWindowWidth() <= this.mobileWidth;
    },
    isTablet() {
      return (
        this.getWindowWidth() >= this.mobileWidth &&
        this.getWindowWidth() <= this.tabletWidth
      );
    }
  },
  mounted() {
    const iframe = document.getElementById("bg-frame");
    iframe.onload = () => setTimeout(() => this.isHeroHidden = false, 2000);
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/app";

.lp-youtube-bg {
    position: fixed;
    top:0;
    left: 0;
    right: 0;
}

.lp-youtube-promotion {
  &--header {
    position: relative;
    width: 100%;
    z-index: 5;
  }

  &--header {
    position: relative;
    -webkit-backdrop-filter: blur(15px) brightness(150%) saturate(180%);
    backdrop-filter: blur(15px) brightness(150%) saturate(180%);
    background: rgba(25, 26, 26, 0.82);
    color: #fff;
    @include transition(background .5s ease);

    &.hidden {
      background: rgba(25, 26, 26, 1);
    }
  }

  &--nav-inner,
  &--header-body-inner,
  &--content-inner {
    max-width: 1200px;
    margin: auto;
  }

  $nav-content-height: 40px;

  &--nav {
    height: 80px;
    position: relative;
  }

  &--nav-inner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &--nav-title h3 {
    font-size: 1.3rem;
    line-height: $nav-content-height;
    height: $nav-content-height;
    padding: 0;
    font-family: $family-regular;
    font-weight: 500;
  }

  &--logo-container {
    width: 100px;
  }

  &--logo {
    height: $nav-content-height;
  }

  &--header-body {
    height: auto;
  }

  &--header-body-inner {
    position: relative;
    padding-top: 144px;
    padding-bottom: 96px;
  }

  &--header-body-inner h1 {
    width: 50%;
    font-size: 2.75rem;
    font-weight: 600;
    line-height: 1.6;
    font-family: $family-bold;
    white-space: nowrap;
  }

  &--content {
    position: relative;
    background-color: #f5f5f5;
    padding-top: 96px;
    padding-bottom: 96px;
  }

  &--content-inner {
    padding-right: 430px;
    p {
      padding: 16px 0;
      @include font-size(18px);
      font-size: calc(18px + 8 * ((100vw - 1280px) / 640));
      line-height: 1.8;
      font-family: $family-light;
      color: #23232d;
    }

    h2 {
      @include font-size(34px);
      line-height: 40px;
      color: #23232d;
      margin-bottom: 48px;
      font-weight: 600;
    }

    .padding-bottom {
      margin-bottom: 48px;
    }

    .addition-row {
      position: relative;
      padding-left: calc(1.625em + 16px);
      margin-bottom: 16px;
      @include font-size(17px);
      line-height: 1.8;
      font-family: $family-light;

      strong {
          display:block;
          @include font-size(20px);
      }

      &::before {
        content: "";
        position: absolute;
        top: 4px;
        left: 0;
        width: 1.625em;
        height: 1.625em;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20'%3E%3Cg fill='%2339B54A'%3E%3Ccircle fill-opacity='.2' cx='10' cy='10' r='10'/%3E%3Cpath d='M8.172 12.162L5.813 10a.996.996 0 10-1.41 1.41l3.07 2.873c.39.39 1.02.39 1.41 0l6.595-6.371a.996.996 0 10-1.41-1.41l-5.896 5.66z'/%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: 50% 50%;
      }
    }
  }

  &--side-image-conainer {
    float: right;
    padding: 20px 0px 10px 30px;

    img {
      max-width: 384px;
      border-radius: 6px;
    }
  }
}

.clients {
  background-color: #4923d8;
  padding: 80px 0 60px;
  position: relative;

  &-inner {
    width: 90vw;
    margin: auto;
  }

  &-title {
    text-transform: capitalize;
    text-align: center;
    color: #fff;
    margin-bottom: 3rem;
    font-family: $family-bold;
    @include font-size(43px);
    font-weight: 600;
  }

  &-list {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    &-item {
      font-size: 1.5em;
      padding: 0.75rem;
      width: 200px;

      img {
          display: block;
          width: 100%;
      }

      &.mitsubishi-logo {
          padding: 30px;
      }
    }
  }
}

.form-wrapper {
  position: absolute;
  top: 240px;
  right: 0;
  background: #fff;
  border-radius: 6px;
  width: 384px;
  height: auto;
  z-index: 99;
}

.form-inner {
  padding: 32px;
}

.mobile-form-section {
  display: none;
}

@media (max-width: 1024px) {
  .lp-youtube-promotion {
    &--nav-inner,
    &--header-body-inner,
    &--content-inner {
      max-width: 950px;
      margin: auto;
    }
    &--header-body-inner h1 {
      font-size: 2rem;
    }
    &--side-image-conainer {
      float:none;
      width:100%;
      padding: 0 0 30px 0;
      img {
        display: block;
        max-width: 100%;
        width: 100%;
        border-radius: 6px;
      }
    }
  }
}

@media (max-width: 992px) {
  .lp-youtube-promotion {
    &--header {
      position: relative;
      width: 100%;
      z-index: 99;
    }

    &--nav-inner,
    &--header-body-inner,
    &--content-inner {
      width: 100%;
      max-width: 100%;
      padding: 0 20px;
    }

    &--header-body-inner h1 {
      width: 100%;
      font-size: 2rem;
      font-weight: 500;
      line-height: 1.6;
      font-family: $family-bold;
      padding: 10vh 5vw;
      white-space: normal;
    }

    &--content {
      padding-top: 5vh;
    }

    &--content-inner {
        p {
            @include font-size(18px);
        }
    }
  }

  .form-wrapper {
    display: none;
  }

  .mobile-form-section {
    padding: 15vh 10vw;
    display: block;
    position: relative;
    background-color: #fff;
  }

  .clients {
    &-list {
      flex-wrap: wrap;

      &-item {
        width: 33.3333%;
        font-size: 1.5em;
        padding: 5vw;

        &.mitsubishi-logo {
          padding: 45px;
        }
      }
    }
  }
}

@media (max-width: 576px) {
.lp-youtube-promotion {
    &--header {
      position: relative;
      width: 100%;
      z-index: 99;
    }

    &--nav-inner,
    &--header-body-inner,
    &--content-inner {
      width: 100%;
      max-width: 100%;
      padding: 0 5vw;
    }

    &--nav-title {
        display:none;
    }

    &--nav-inner {
        padding-top: 5vw;
        display: block;
    }

    &--header-body-inner h1 {
      width: 100%;
      font-size: 2.75rem;
      font-weight: 600;
      line-height: 1.6;
      font-family: $family-bold;
      padding: 10vh 0 10vh;

      &.ja {
        @include font-size(22px);
      }
    }

    &--content {
      padding-top: 5vh;
    }

    &--content-inner h2 {
      font-weight: 600;
      @include font-size(20px);
      line-height: 33px;
      margin-bottom: 33px;
    }

    &--content-inner h2.ja {
      font-weight: 600;
      @include font-size(16px);
      line-height: 33px;
      margin-bottom: 33px;
    }

    &--content-inner  p {
      @include font-size(15px);
    }

    &--content-inner .addition-row {
      @include font-size(14px);
    }

    &--content-inner .addition-row strong {
      @include font-size(15px);
    }

    &--side-image-conainer {
        float: none;
        padding: 0;

        img {
            display: blog;
            width: 100%;
        }
    }
  }

  .mobile-form-section {
    padding: 10vh 5vw;
  }



  .clients {
    
    .clients-inner  {
      h4.ja {
        display: block;
        @include font-size(26px);
      }
    }


    &-list {
      flex-wrap: wrap;

      &-item {
        &.mitsubishi-logo {
          padding: 30px;
        }
      }
    }
  }
  
}
</style>
