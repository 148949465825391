<template>
  <div>
    <div class="form-group">
      <input
        :disabled="isFormDisabled"
        v-model="name"
        :class="{ error: isNameTouched && !isNameExists }"
        type="text"
        @blur="isNameTouched = true"
        placeholder="御担当者名"
      />
      <div class="error-message">
        <span v-if="isNameTouched && !isNameExists">{{
          "必須入力項目です。"
        }}</span>
      </div>
    </div>
    
    <div class="form-group">
      <input
        :disabled="isFormDisabled"
        v-model="email"
        :class="{ error: isEmailTouched && (!isEmailExists || !isEmailValid) }"
        type="text"
        @blur="isEmailTouched = true"
        placeholder="メールアドレス"
      />
      <div class="error-message">
        <span v-if="isEmailTouched && !isEmailExists">{{
          "必須入力項目です。"
        }}</span>
        <span v-if="isEmailTouched && isEmailExists && !isEmailValid">{{
          "無効なメールアドレスです。"
        }}</span>
      </div>
    </div>
    <div class="form-group">
      <input
        :disabled="isFormDisabled"
        v-model="requirmentsType"
        type="text"
        @blur="isRequirmentTypeTouched = true"
        placeholder='会社名'
      />
    </div>


    <div class="form-group">
      <input
        :disabled="isFormDisabled"
        v-model="youtube"
        :class="{ error: isYouTubeTouched && !isYoutubeExists }"
        type="text"
        @blur="isYouTubeTouched = true"
        placeholder='YouTubeのページ（URL）'
      />
      <div class="error-message">
        <span v-if="isYouTubeTouched && !isYoutubeExists">{{
          "必須入力項目です。"
        }}</span>
      </div>
    </div>
    
    
    <div class="form-group">
      <div
        class="desctop-select"
        :class="{
          droped: isCountrySelectShown,
          error: isCountriesTouched && !isCountryExists
        }"
      >
        <span
          v-on:click="showCloseCountriesSelect"
          v-if="activeCountryCode == null"
          >{{ "国" }}</span
        >
        <template v-for="(item, index) in countries">
          <span
            v-on:click="showCloseCountriesSelect"
            v-if="activeCountryCode == index"
            :key="index"
            >{{ item['ja'] }}</span
          >
        </template>
        <ul>
          <template v-for="(item, index) in countries">
            <li v-on:click="activeCountryCode = index" :key="index">
              {{ item['ja'] }}
            </li>
          </template>
        </ul>
      </div>
      <div class="mobile-select">
        <select
          v-model="activeCountryCode"
          @change="activeCountryCode = $event.target.value"
          :class="{ error: isCountriesTouched && !isCountryExists }"
        >
          <option selected disabled value="null">{{ "国" }}</option>
          <template v-for="(item, index) in countries">
            <option :value="index" :key="index">{{ item['ja'] }}</option>
          </template>
        </select>
      </div>
      <div class="error-message">
        <span v-if="isCountriesTouched && !isCountryExists">{{
          "必須入力項目です。"
        }}</span>
      </div>
    </div>
    
    
    <div class="form-group">
      <div
        class="desctop-select"
        :class="{
          droped: isIndustrySelectShown,
          error: isIndustriesTouched && !isIndustryExists
        }"
      >
        <span
          v-on:click="showCloseIndustriesSelect"
          v-if="activeIndustryCode == null"
          >{{ '業界' }}</span
        >
        <template v-for="(item, index) in industries">
          <span
            v-on:click="showCloseIndustriesSelect"
            v-if="activeIndustryCode == index"
            :key="index"
            >{{ item['ja'] }}</span
          >
        </template>
        <ul>
          <template v-for="(item, index) in industries">
            <li v-on:click="activeIndustryCode = index" :key="index">
              {{ item['ja'] }}
            </li>
          </template>
        </ul>
      </div>
      <div class="mobile-select">
        <select
          @change="activeIndustryCode = $event.target.value"
          :class="{ error: isIndustriesTouched && !isIndustryExists }"
        >
          <option selected disabled value="null">{{ '業界' }}</option>
          <template v-for="(item, index) in industries">
            <option :value="index" :key="index">{{ item['ja'] }}</option>
          </template>
        </select>
      </div>
      <div class="error-message">
        <span v-if="isIndustriesTouched && !isIndustryExists">{{
          "必須入力項目です。"
        }}</span>
      </div>
    </div>


    <div class="form-group">
      <div
        class="desctop-select"
        :class="{
          droped: isObjectiveSelectShown,
          error: isObjectivesTouched && !isObjectivesExists
        }"
      >
        <span
          v-on:click="showCloseObjectiveSelect"
          v-if="activeObjective == null"
          >{{ '広告の目的' }}</span
        >
        <template v-for="(item, index) in objectives">
          <span
            v-on:click="showCloseObjectiveSelect"
            v-if="activeObjective == index"
            :key="index"
            >{{ item['ja'] }}</span
          >
        </template>
        <ul>
          <template v-for="(item, index) in objectives">
            <li v-on:click="activeObjective = index" :key="index">
              {{ item['ja'] }}
            </li>
          </template>
        </ul>
      </div>
      <div class="mobile-select">
        <select
          @change="activeObjective = $event.target.value"
          :class="{ error: isObjectivesTouched && !isObjectivesExists }"
        >
          <option selected disabled value="null">{{ '広告の目的' }}</option>
          <template v-for="(item, index) in objectives">
            <option :value="index" :key="index">{{ item['ja'] }}</option>
          </template>
        </select>
      </div>
      <div class="error-message">
        <span v-if="isObjectivesTouched && !isObjectivesExists">{{
          "必須入力項目です。"
        }}</span>
      </div>
    </div>

    <div class="form-group">
      <div
        class="desctop-select"
        :class="{
          droped: isBudgetSelectShown,
          error: isBudgetTouched && !isBudgetExists
        }"
      >
        <span
          v-on:click="showCloseBudgetSelect"
          v-if="activeBudget == null"
          >{{ '広告の予算' }}</span
        >
        <template v-for="(item, index) in budget">
          <span
            v-on:click="showCloseBudgetSelect"
            v-if="activeBudget == index"
            :key="index"
            >{{ item['ja'] }}</span
          >
        </template>
        <ul>
          <template v-for="(item, index) in budget">
            <li v-on:click="activeBudget = index" :key="index">
              {{ item['ja'] }}
            </li>
          </template>
        </ul>
      </div>
      <div class="mobile-select">
        <select
          @change="activeBudget = $event.target.value"
          :class="{ error: isBudgetTouched && !isBudgetExists }"
        >
          <option selected disabled value="null">{{ '広告の予算' }}</option>
          <template v-for="(item, index) in budget">
            <option :value="index" :key="index">{{ item['ja'] }}</option>
          </template>
        </select>
      </div>
      <div class="error-message">
        <span v-if="isBudgetTouched && !isBudgetExists">{{
          "必須入力項目です。"
        }}</span>
      </div>
    </div>

    <div class="form-group">
      <div
        class="desctop-select"
        :class="{
          droped: isViewsCountSelectShown,
          error: isViewersTouched && !isViewersExists
        }"
      >
        <span
          v-on:click="showCloseViewsCountSelect"
          v-if="activeViewsCountCode == null"
          >{{ '必要な再生数' }}</span
        >
        <template v-for="(item, index) in viewsCount">
          <span
            v-on:click="showCloseViewsCountSelect"
            v-if="activeViewsCountCode == index"
            :key="index"
            >{{ item['ja'] }}</span
          >
        </template>
        <ul>
          <template v-for="(item, index) in viewsCount">
            <li v-on:click="activeViewsCountCode = index" :key="index">
              {{ item['ja'] }}
            </li>
          </template>
        </ul>
      </div>
      <div class="mobile-select">
        <select
          v-model="activeViewsCountCode"
          :class="{ error: isViewersTouched && !isViewersExists }"
        >
          <option selected disabled value="null"
            >{{ '必要な再生数' }}</option
          >
          <template v-for="(item, index) in viewsCount">
            <option :value="index" :key="index">{{ item['ja'] }}</option>
          </template>
        </select>
      </div>
      <div class="error-message">
        <span v-if="isViewersTouched && !isViewersExists">{{
          "必須入力項目です。"
        }}</span>
      </div>
    </div>
    <div class="form-group">
      <label>
          D&D promotionはお客様の個人情報保護に取り組んでいます。D&D promotionの個人情報の取り扱いについての詳細は<a class='a-common-gray' href='/privacy-policy'>プライバシーポリシー</a>をご覧ください。
      </label>
      <div class="error-message">
        <span v-if="isMessageSent" class="success-note">
          {{ "お問い合わせを受け付けました。後程担当者よりご連絡させていただきます。" }}
        </span>
      </div>
    </div>
    <div class="form-group">
      <button v-on:click="onSubmit" :disabled="isFormDisabled">
        <span class="button-text">
          {{ '送信' }}
        </span>
        <span class="button-bg">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </span>
      </button>
    </div>
  </div>
</template>
<script>
import COUNTRIES from "./../../resorces/countries.json";
import INDUSTRIES from "./../../resorces/industries.json";
import VIEWSCOUNT from "./../../resorces/views-amount.json";
import OBJECTIVES from "./../../resorces/objectives.json";
import BUDGET from "./../../resorces/budget.json";
const emailCheckRegex = /\S+@\S+\.\S+/;

export default {
  data() {
    return {
      isMessageSent: false,
      isFormDisabled: false,

      youtube: null,
      isYouTubeTouched: false,

      requirmentsType: null,
      isRequirmentTypeTouched: false,

      lastName: null,
      isLastNameTouched: false,

      name: null,
      isNameTouched: false,
      
      email: null,
      isEmailTouched: false,

      countries: null,
      activeCountryCode: 0,
      isCountrySelectShown: false,
      isCountriesTouched: false,

      industries: INDUSTRIES,
      activeIndustryCode: null,
      isIndustrySelectShown: false,
      isIndustriesTouched: false,

      objectives: OBJECTIVES,
      activeObjective: null,
      isObjectiveSelectShown: false,
      isObjectivesTouched: false,

      budget: BUDGET,
      activeBudget: null,
      isBudgetSelectShown: false,
      isBudgetTouched: false,

      viewsCount: VIEWSCOUNT,
      activeViewsCountCode: null,
      isViewsCountSelectShown: false,
      isViewersTouched: false,

      mobileWidth: 793,
      tabletWidth: 992
    };
  },
  methods: {
    getEnglish(strValue, dictionary) {
      let res = strValue;
      for(let i = 0; i < dictionary.length; i++) {
        if(dictionary[i]['ja'] || dictionary[i]['en']) {
          res = dictionary[i]['en'];
        }
      }
      return res;
    },
    hasValue(val) {
      return val !== null && val !== undefined && val !== "";
    },
    showCloseCountriesSelect(event) {
      if (this.isFormDisabled) {
        return;
      }

      event.stopPropagation();

      this.isIndustrySelectShown = false;
      this.isViewsCountSelectShown = false;
      this.isObjectiveSelectShown = false;
      this.isBudgetSelectShown = false;

      this.isCountrySelectShown = !this.isCountrySelectShown;
    },
    showCloseIndustriesSelect(event) {
      if (this.isFormDisabled) {
        return;
      }

      event.stopPropagation();

      this.isCountrySelectShown = false;
      this.isViewsCountSelectShown = false;
      this.isObjectiveSelectShown = false;
      this.isBudgetSelectShown = false;

      this.isIndustrySelectShown = !this.isIndustrySelectShown;
    },
    showCloseObjectiveSelect(event) {
      if (this.isFormDisabled) {
        return;
      }

      event.stopPropagation();

      this.isCountrySelectShown = false;
      this.isIndustrySelectShown = false;
      this.isViewsCountSelectShown = false;
      this.isBudgetSelectShown = false;

      this.isObjectiveSelectShown = !this.isObjectiveSelectShown;
    },
    showCloseBudgetSelect(event) {
      if (this.isFormDisabled) {
        return;
      }

      event.stopPropagation();

      this.isCountrySelectShown = false;
      this.isIndustrySelectShown = false;
      this.isViewsCountSelectShown = false;
      this.isObjectiveSelectShown = false;

      this.isBudgetSelectShown = !this.isBudgetSelectShown;
    },
    showCloseViewsCountSelect(event) {
      if (this.isFormDisabled) {
        return;
      }

      event.stopPropagation();

      this.isCountrySelectShown = false;
      this.isIndustrySelectShown = false;
      this.isObjectiveSelectShown = false;
      this.isBudgetSelectShown = false;

      this.isViewsCountSelectShown = !this.isViewsCountSelectShown;
    },
    onSubmit() {
      this.setValuesToToched(true);

      if (!this.isFormValid() || this.isFormDisabled) {
        return;
      }

      this.isFormDisabled = true;

      const successHand = () => {
        this.isMessageSent = true;

        this.setValuesToToched(false);

        this.email = null;
        this.name = null;
        this.lastName = null;
        this.youtube = null;
        this.requirmentsType = null;
        this.activeCountryCode = null;
        this.activeIndustryCode = null;
        this.activeViewsCountCode = null;
        this.activeObjective = null;
        this.activeBudget = null;

        this.isFormDisabled = true;

        window.gtag('event', 'conversion', {'send_to': 'AW-694986123/l88yCJ6a-swBEIvLsssC'});
      };
      this.$http({
        url: "contact-youtube-lp",
        method: "POST",
        data: {
          name: this.name,
          email: this.email,
          youtube_url: this.youtube,
          requirments_type: this.requirmentsType,
          country: this.countries[this.activeCountryCode]['en'],
          industry: this.industries[this.activeIndustryCode]['en'],
          objective: this.objectives[this.activeObjective]['en'], 
          budget: this.budget[this.activeBudget]['en'],
          views_count: this.viewsCount[this.activeViewsCountCode]['en'],
          locale: this.$i18n.locale,
          device: this.getDeviseName()
        }
      })
        .then(successHand)
        .catch(() => (this.isSentError = true));
    },
    isFormValid() {
      return (
        this.isEmailExists &&
        this.isEmailValid &&
        this.isYoutubeExists &&
        this.isIndustriesTouched &&
        this.isNameExists &&
        this.isLastNameExists &&
        this.isCountryExists &&
        this.isIndustryExists &&
        this.isViewersExists &&
        this.isObjectivesExists &&
        this.isBudgetExists
      );
    },
    setValuesToToched(val) {
      this.isEmailTouched = val;
      this.isNameTouched = val;
      this.isLastNameTouched = val;
      this.isYouTubeTouched = val;
      this.isRequirmentTypeTouched = val;
      this.isCountriesTouched = val;
      this.isIndustriesTouched = val;
      this.isViewersTouched = val;
      this.isObjectivesTouched = val;
      this.isBudgetTouched = val;
    },
    getDeviseName() {
      if (this.isTablet()) {
        return "Tablet";
      }

      if (this.isMobile()) {
        return "Mobile";
      }

      return "Desktop";
    },
    isMobile() {
      return this.getWindowWidth() <= this.mobileWidth;
    },
    isTablet() {
      return (
        this.getWindowWidth() >= this.mobileWidth &&
        this.getWindowWidth() <= this.tabletWidth
      );
    },
    getWindowWidth() {
      return (
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
      );
    }
  },
  mounted() {
    const usNode = COUNTRIES.find(c => c.code == 'US');
    const jaNode = COUNTRIES.find(c => c.code == 'JP');

    const _countries = COUNTRIES.filter(x => x.code != 'US' && x.code != 'JP'); 

    this.countries = _countries.sort(function(a, b) {
      if (a.name > b.name) {
        return 1;
      }

      if (a.name < b.name) {
        return -1;
      }

      return 0;
    });

    this.countries.unshift(usNode);
    this.countries.unshift(jaNode);

    document.addEventListener("click", () => {
      if (this.isCountrySelectShown) {
        this.isCountrySelectShown = false;
        this.isCountriesTouched = true;
      }

      if (this.isIndustrySelectShown) {
        this.isIndustrySelectShown = false;
        this.isIndustriesTouched = true;
      }

      if (this.isViewsCountSelectShown) {
        this.isViewsCountSelectShown = false;
        this.isViewersTouched = true;
      }

      if (this.isObjectiveSelectShown) {
        this.isObjectiveSelectShown = false;
        this.isObjectivesTouched = true;
      }

      if (this.isBudgetSelectShown) {
        this.isBudgetSelectShown = false;
        this.isBudgetTouched = true;
      }
    });
  },
  computed: {
    isEmailExists() {
      return this.hasValue(this.email);
    },
    isNameExists() {
      return this.hasValue(this.name);
    },
    isLastNameExists() {
        return true;
    },
    isEmailValid() {
      return emailCheckRegex.test(this.email);
    },
    isYoutubeExists() {
      return this.hasValue(this.youtube);
    },
    isCountryExists() {
      return this.hasValue(this.activeCountryCode);
    },
    isIndustryExists() {
      return this.hasValue(this.activeIndustryCode);
    },
    isObjectivesExists() {
      return this.hasValue(this.activeObjective);
    },
    isBudgetExists() {
      return this.hasValue(this.activeBudget);
    },
    isViewersExists() {
      return this.hasValue(this.activeViewsCountCode);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../scss/app";

.error-message {
  span {
    color: #f36;
    font-family: $family-light;
  }
  .success-note {
    color: green;
    font-family: $family-light;
  }
}

$duration: 0.35s;
$charCoutn: 100;
$colorBlack: rgb(25, 26, 26);
$colorLight: rgb(255, 255, 255);
$colorMiddle: rgb(255, 217, 0);
$colorActive: rgb(73, 35, 216);

.desctop-select {
  position: relative;

  &.error span {
    border-color: #f36;
  }

  span {
    font-family: $family-light;
    position: relative;
    display: block;
    border: 1px solid #d0d5d8;
    border-radius: 2px;
    padding: 18px 15px;
    cursor: pointer;
    color: #8a959e;
    @include font-size(18px);

    &::after {
      content: "";
      position: absolute;
      margin-top: -0.6em;
      border: solid transparent;
      border-bottom-color: #a4abb1;
      border-width: 0.3em;
      right: 0.8em;
      top: 50%;
      z-index: 1;
    }

    &::before {
      content: "";
      position: absolute;
      margin-top: 0.2em;
      border: solid transparent;
      border-width: 0.3em;
      border-top-color: #a4abb1;
      right: 0.8em;
      top: 50%;
      z-index: 1;
    }
  }

  ul {
    display: none;
    position: absolute;
    background: #fff;
    width: 100%;
    border: 1px solid #d0d5d8;
    border-top: 0px;

    li {
      cursor: pointer;
      color: #8a959e;
      @include font-size(18px);
      padding: 18px 15px;
      font-family: $family-light;

      &:hover {
        background: #d0d5d8;
      }
    }
  }

  &.droped {
    ul {
      z-index: 9;
      display: block;
      max-height: 40vh;
      overflow-y: scroll;
    }
  }
}

.mobile-select {
  position: relative;
  display: none;
  cursor: pointer;
  color: #8a959e;
  @include font-size(18px);

  &::after {
    content: "";
    position: absolute;
    margin-top: -0.6em;
    border: solid transparent;
    border-bottom-color: #a4abb1;
    border-width: 0.3em;
    right: 0.8em;
    top: 50%;
    z-index: 1;
  }

  &::before {
    content: "";
    position: absolute;
    margin-top: 0.2em;
    border: solid transparent;
    border-width: 0.3em;
    border-top-color: #a4abb1;
    right: 0.8em;
    top: 50%;
    z-index: 1;
  }
}

.form-group {
  width: 100%;
  padding: 0 0 20px 0;

  &:last-child {
    padding: 0;
  }

  input,
  textarea,
  select,
  button {
    display: block;
    width: 100%;
    outline: none;
    padding: 5px;
    border: 1px solid #d0d5d8;
    border-radius: 2px;
    resize: none;
    padding: 18px 15px;
    @include font-size(18px);
    color: #8a959e;
    background: transparent;
    font-family: $family-light;

    &::placeholder {
      color: #8a959e;
    }

    &.error {
      border-color: #f36;
    }
  }

  textarea {
    height: 224px;
  }

  label {
    display: inline-block;
    @include font-size(18px);
    color: #8a959e;
    background: transparent;
    font-family: $family-light;
    #send-emails {
      display: inline-block !important;
      width: 7%;
    }
  }

  button {
    position: relative;
    border-radius: 5px;
    height: 63px;
    border: 0px solid transparent;
    border-radius: 2px;
    overflow: hidden;

    .button-text {
      line-height: 66px;
      position: absolute;
      text-transform: uppercase;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      font-family: $family-regular;
      @include font-size(20px);
      color: #fff;
      background-color: transparent;
      z-index: 1;
      overflow: hidden;
    }

    .button-bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #1d1f1f;
      z-index: 0;
      display: flex;

      span {
        display: block;
        width: 100%;
        height: 100%;
        @include transition(color-backward $duration);
        @include animation(color-backward $duration);
        @include animation-fill-mode(both);

        @for $i from 1 through $charCoutn {
          &:nth-child(#{$charCoutn}n + #{$i}) {
            @include animation-delay(
              ($duration / $charCoutn) * ($charCoutn - $i)
            );
          }
        }
      }
    }

    &:hover {
      .button-bg {
        span {
          @include transition(color-forward $duration);
          @include animation(color-forward $duration);
          @include animation-fill-mode(both);

          @for $i from 1 through $charCoutn {
            &:nth-child(#{$charCoutn}n + #{$i}) {
              @include animation-delay($duration / $charCoutn * $i);
            }
          }
        }
      }
    }

    &:disabled,
    &:disabled:hover {
      background-color: #ccc;
      .button-bg {
        background-color: #ccc;
        span {
          @include animation(none);
          background-color: #ccc;
        }
      }
    }
  }
}

@include keyframes(color-forward) {
  0% {
    background: $colorBlack;
  }
  10% {
    background: rgb(246, 189, 37);
  }
  20% {
    background: rgb(239, 161, 55);
  }
  30% {
    background: rgb(231, 133, 73);
  }
  40% {
    background: rgb(222, 100, 94);
  }
  50% {
    background: rgb(214, 68, 114);
  }
  60% {
    background: rgb(180, 59, 139);
  }
  70% {
    background: rgb(144, 51, 165);
  }
  80% {
    background: rgb(73, 35, 216);
  }
  100% {
    background: $colorActive;
  }
}

@include keyframes(color-backward) {
  0% {
    background: $colorActive;
  }
  100% {
    background: $colorBlack;
  }
}

@media (max-width: 992px) {
  .mobile-select {
    display: block;
  }

  .desctop-select {
    display: none;
  }
}
</style>
